import React, { Component } from 'react';
import './App.css';
import { Image, Container, Col, Row, Button, Modal } from 'react-bootstrap';
import logo from './assets/images/logo.svg';
import newYear from './assets/images/headline.svg';
import arrows from './assets/images/arrows.svg';
import graphicFooter from './assets/images/footer.svg'
export default class App extends Component {
	constructor(props, context) {
		super(props, context);

		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);

		this.state = {
			show: false
		};
	}

	handleClose() {
		this.setState({ show: false });
	}

	handleShow() {
		this.setState({ show: true });
	}

	render() {
		return (
			<div>
				<Modal size="lg" centered show={this.state.show} onHide={this.handleClose}>
					<Modal.Body className="text-center">
						<h2 className="modal-titile-custom">
							<Image fluid className="logo-modal" src={logo} alt="logo born digital" />
						</h2>
						Znamo da su dani naših prijatelja i prijatelja dinamični i nepredvidivi. Zato, ako u bilo kom
						trenutku odlučite da nam se ipak pridružite, uputite se pravac naše "gajbe" gdje ste uvijek
						dobro došli.
						<br/><br/>
					</Modal.Body>
					<Modal.Footer className="text-center">
						<Button
							className="button-answer yes modal-button-custom"
							variant="secondary"
							onClick={this.handleClose}
						>
							Zatvori
						</Button>
					</Modal.Footer>
				</Modal>
				<Container>
					<Row>
						<Col className="text-center">
							<Image fluid className="logo" src={logo} alt="logo born digital" />
						</Col>
					</Row>
					<Row className="justify-content-center">
						<Col lg={8} className="text-center">
							<Image fluid className="new-year-img" src={newYear} alt="logo born digital" />
						</Col>
					</Row>
					<Row className="justify-content-center">
						<Col lg={12} className="text-center">
							<Image fluid className="arrows" src={arrows} alt="arrows icon" />
						</Col>
						<Col lg={12} className="text-center">
							<h2 className="titile">Dragi prijatelji i saradnici,</h2>
						</Col>
						<Col lg={8} className="text-center">
							<p className="text">
								Radosni smo što sa vama možemo podijeliti vijest da naš mali preduzetnički projekat slavi svoju prvu godišnjicu postojanja i rada!
							</p>
							<p className="text">
								Počeli smo sa nekoliko ideja, ali sada su one mnogostruke - ne samo po broju već i po kreativnosti. Svi napori koje smo uložili u ta ostvarenja, uključujući nezamjenjiva iskustva stečena na tom putu, rezultat su vašeg povjerenja u naš tim.
							</p>
							<p className="text">
								Prvi rođendani su uvijek posebni, jer označavaju prelaz od početnika ka kompletnosti. Tokom prve godine raste se polako i mukotrpno, učeći iz svega što dolazi u vidu novog; poslije toga igra postaje uzbudljivija i intenzivnija. Upravo tome se radujemo, iščekujući da prema našim saradnicima budemo još bolji i sadržajniji.
							</p>
							<p className="text">
								Ovo nije samo korporativna godišnjica, to je godišnjica porodice koja je ojačala prkoseći izazovnom periodu kroz kojeg smo svi prošli. 
							</p>
							<p className="text">
								U želji da sa vama podijelimo našu radost i dobru energiju koja nas inspiriše da sa vama gradimo prije svega kvalitetne ljudske veze, a zatim isporučujemo i nezaboravna digitalna iskustva, pozivamo vas da nam se pridružite u tom slavlju.
							</p>
						</Col>
					</Row>
					{/* sstaro */}
					<Row className="justify-content-center">
						<Col lg={8} className="text-center">						
							<p className="question">Izaberite jednu od ponuđenih opcija...</p>
						</Col>
						<Col xs={6} className="text-right">
							<a
								href="https://forms.gle/DK5zPopySUxiZvC66"
								target="_blank"
								rel="noopener noreferrer"
							>
								<Button className="button-answer yes">dolazim</Button>
							</a>
						</Col>
						<Col xs={6} className="text-left">
							<Button className="button-answer yes" onClick={this.handleShow}>
								ne dolazim
							</Button>
						</Col>
					</Row>
				</Container>

				<Container fluid >
					<Row>
						<Col className="p-0">
							<Image fluid className="footer-graphic" src={graphicFooter} alt="graphic footer" />
						</Col>
					</Row>
				</Container>

				{/* <Container fluid className="footer m-0">
					<Row className="dashed">
						<Col className="text-center">
							<Image fluid className="logoFooter" src={logoFooter} alt="logo born digital" />
						</Col>
					</Row>
					<Row className="justify-content-center">
						<Col className="text-center footer-links-position">
							<p className="footer-links">
								<a href="https://g.page/borndigital?share" target="_blank" rel="noopener noreferrer">
									<b>A:</b> Dr. Mladena Stojanovića 93
								</a>
							</p>
							<p className="footer-links">
								<a href="mailto:hello@borndigital.ba">
									<b>E:</b> hello@borndigital.ba
								</a>
							</p>
							<p className="footer-links">
								<a href="tel:+38765600402">
									<b>T1:</b> +38765600402
								</a>
							</p>
							<p className="footer-links">
								<a href="tel:+38765243789">
									<b>T2:</b> +38765243789
								</a>
							</p>
						</Col>
					</Row>
				</Container>
				<Container fluid className="m-0">
					<Row className="justify-content-center">
						<Col className="text-center copyright">
							<p className="footer-links">© Copyright 2020 | Born Digital</p>
						</Col>
					</Row>
				</Container> */}
			</div>
		);
	}
}
